<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Schedule Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Title:" label-for="title">
                                    <b-form-input id="title" type="text" placeholder="Enter  title" autocomplete="off"
                                        v-model="title" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
        
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Branch Name:" label-for="branch_id">
                                <b-form-select id="branch_id" v-model="branch_id" :options="branches" required>
                                </b-form-select>
                            </b-form-group>
                            </div>
                           
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Schedule Date:" label-for="date">
                                    <b-form-input id="date" type="date" placeholder="Enter  date" autocomplete="off"
                                        v-model="date" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Schedule Time:" label-for="time">
                                    <b-form-input id="time" type="time" placeholder="Enter schedule time" autocomplete="off"
                                        v-model="time" required>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Schedule Type:" label-for="type">
                                    <b-form-select id="type" v-model="type" :options="statusOptions" required>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <!-- <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Schedule Status:" label-for="status">
                                    <b-form-input id="status" type="status" placeholder="Enter schedule status" autocomplete="off"
                                        v-model="status" required>
                                    </b-form-input>
                                </b-form-group>
                            </div> -->
                            <!-- <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label="Schedule Status:" label-for="status">
                                    <b-form-select
                                id="status"
                                v-model="status"
                                :options="statusOption"
                                required
                                ></b-form-select>
                                </b-form-group>
                            </div> -->
                        </div>

                     
                        <b-button type="submit" variant="primary" class="mb-8 mr-8" :disabled="isLoading">
                            <span v-if="!isLoading">Submit</span>
                            <b-spinner v-else class="mb-8 mr-8" variant="primary" small></b-spinner>
                        </b-button>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
            <code class="hljs html">
              {{ codeText }}
            </code>
          </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,
            title: "",
            time: "",
            date: "", 
            type:"",
            status:"",
            branch_id:"",
            branches:[],
            statusOptions: [
            { value: null, text: 'Select Schedule Type:' },
            { value: 'onsite', text: 'Onsite' },
            { value: 'online', text: 'Online' }
            ],
            statusOption: [
        { value: null, text: 'Select Schedule Status' },
        { value: 'Pending', text: 'Pending' },
        { value: 'Approved', text: 'Approved' },
      ],
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },

    created() {
        // Load the clients data when the component is created
        axios
        const userId = this.$route.params.id;
        axios
            .get(`scheduleShow/${userId}`)
            .then((response) => {

                this.editedUser = response.data.data;
                this.title = this.editedUser.title;
                this.date = this.editedUser.date;
                this.time = this.editedUser.time;
                this.type = this.editedUser.type;
                this.status = this.editedUser.status;
                this.branch_id = this.editedUser.branch_id;
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    },
    mounted() {
    axios.post('branch')
        .then(response => {
            if (response.data.success && response.data.data) {
                this.branches = response.data.data.map(branch => ({
                    value: branch.id, 
                    text: branch.name  
                }));
                console.log('Branches:', this.branches);
            } else {
                console.error('Unexpected response format:', response.data);
            }
        })
        .catch(error => {
            console.log('Error fetching branches:', error);
        });
},

    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
      
        addUser() {
            this.isLoading = true;
            // Create a FormData object to handle the image file

            const formData = new FormData();
            formData.append("title", this.title);
            formData.append("time", this.time);
            formData.append("date", this.date);
            formData.append("type", this.type);
            formData.append("status", this.status);
            formData.append("branch_id", this.branch_id);
            axios
                .post(`scheduleUpdate/${this.editedUser.id}`, formData)
                .then((response) => {
                    console.log(response.data);
                    this.$bvToast.toast("Schedule Update successfully!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                        appendToast: true,
                        toaster: "b-toaster-top-right",
                        autoHideDelay: 5000,
                        variant: "primary", 
                    });
                    this.isLoading = false;
                    this.$router.push({ name: 'ListSchedule' });
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    console.log(error.response.data);
                    this.isLoading = false;
                });
        },

        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                // Set the selected file to the data property
                this.image = file;
            }
        },


        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
    },
};
</script>